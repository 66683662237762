import {BaseService} from "@/core/services/BaseService";

class ScanService extends BaseService<any> {
  endpoint = "/scan";

  async scanEntity(objectType, objectId) {
    return this.http().get(this.endpoint + "/entity/" + objectType + "/" + objectId).then(res => {
      return res.data;
    })
  }

  async event(objectType: any, objectId: any) {
    return this.http().get(this.endpoint + "/event/" + objectType + "/" + objectId).then(res => {
      return res.data;
    })
  }


  async refresh() {
    return this.http().get(this.endpoint + "/refresh").then(res => {
      return res.data;
    })
  }
}

export default new ScanService();