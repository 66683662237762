import {BaseService} from "@/core/services/BaseService";

class InvoiceService extends BaseService<any> {
  endpoint = "/invoice";

  async exportPdf(id) {
    return this.http().get(this.endpoint + "/" + id + "/export/pdf", {responseType: "blob"}).then(res => {
      return res
    })
  }

  async exportEOB(id, cheque) {
    return this.http().get(this.endpoint + "/" + id + "/export/eob?cheque=" + cheque, {responseType: "blob"}).then(res => {
      return res
    })
  }

  async exportRepriced(id) {
    return this.http().get(this.endpoint + "/" + id + "/export/repriced", {responseType: "blob"}).then(res => {
      return res
    })
  }

  async duplicate(id, billed, type, dos) {
    return this.http().get(this.endpoint + "/" + id + "/duplicate?billed=" + billed + "&type=" + type + "&dos=" + dos).then(res => {
      return res.data
    })
  }

  async reprice(id, data) {
    return this.http().post(this.endpoint + "/" + id + "/reprice", data).then(res => {
      return res.data;
    })
  }

  async nonReprice(id) {
    return this.http().patch(this.endpoint + "/" + id + "/none-reprice").then(res => {
      return res.data;
    })
  }

  async adjudicate(id, data) {
    return this.http().post(this.endpoint + "/" + id + "/adjudicate", data).then(res => {
      return res.data;
    })
  }

  async move(id: any, value) {
    return await this.http().put(this.endpoint + "/" + id + "/move", value).then(res => {
      return res.data
    })
  }

  async original(id) {
    return await this.http().get(this.endpoint + "/" + id + "/original").then(res => {
      return res.data;
    })
  }

  async finalize(id) {
    return await this.http().patch(this.endpoint + "/" + id + "/finalize").then(res => {
      return res.data
    })
  }

  async activate(id) {
    return await this.http().patch(this.endpoint + "/" + id + "/re-activate").then(res => {
      return res.data
    })
  }

  async removeDuplicate(id) {
    return await this.http().patch(this.endpoint + "/" + id + "/duplicate").then(res => {
      return res.data
    })
  }

  async updateGhipStatus(id, data) {
    return this.http().put(this.endpoint + "/" + id + "/ghip-update", data).then(res => {
      return res.data
    })
  }

  async sanctionCheck(id) {
    return this.http().get(this.endpoint + "/" + id + "/sanctions-check").then(res => {
      return res.data
    })
  }

  async fastPay(id) {
    return this.http().put(this.endpoint + "/" + id + "/fast-pay").then(res => {
      return res.data
    })
  }
}

export default new InvoiceService();