import {BaseService} from "@/core/services/BaseService";
import {ENDPOINT} from "@/core/config/Constant";
import {generatorQueryString} from "@/core/helpers/functions";
import {IFilter} from "@/core/entity/IFilter";

class UserService extends BaseService<any> {
  endpoint = ENDPOINT.USER;

  async resetPassword(id) {
    return await this.http().put(this.endpoint + "/" + id + "/reset-password").then(res => {
      return res.data;
    })
  }

  async loginHistory(id: string, filter: IFilter) {
    const queryString = generatorQueryString(filter);
    return await this.http().get(this.endpoint + "/" + id + "/login-history?" + queryString).then(res => {
      return res.data;
    })
  }

  public async updateProfile(data): Promise<any> {
    return await this.http().put(this.endpoint + "/update-profile", data).then(res => {
      return res.data;
    })
  }

  public async login(data): Promise<any> {
    return this.http().post(this.endpoint + "/login", data).then(res => {
      return res.data
    })
  }

  public async forgotPassword(data): Promise<any> {
    return this.http().post(this.endpoint + "/forgot-password", data).then(res => {
      return res.data
    })
  }

  public async verify(data): Promise<any> {
    return this.http().post(this.endpoint + "/verify", data).then(res => {
      return res.data
    })
  }

  public async changePassword(data): Promise<any> {
    return this.http().put(this.endpoint + "/change-password", data).then(res => {
      return res.data
    })
  }

  async presetMfa(id, data) {
    return await this.http().put(this.endpoint + "/" + id + "/preset-mfa", data).then(res => {
      return res.data;
    })
  }

  async forceUpdatePassword(id, data) {
    return await this.http().put(this.endpoint + "/" + id + "/password/force-update", data).then(res => {
      return res.data;
    })
  }

  async updateStatus(id, status) {
    return await this.http().put(this.endpoint + "/" + id + "/status", {status: status}).then(res => {
      return res.data;
    })
  }

  public async roles(id: string) {
    return await this.http().get(this.endpoint + "/" + id + "/roles").then(res => {
      return res.data
    })
  }

  public async addRole(id: any, model: any) {
    return await this.http().put(this.endpoint + "/" + id + "/roles", model).then(res => {
      return res.data
    })
  }

  public async present(data: any): Promise<any> {
    return this.http().post(this.endpoint + "/resent-mfa", data).then(res => {
      return res.data
    })
  }

  public async showMFA(id) {
    return this.http().get(this.endpoint + "/" + id + "/mfa").then(res => {
      return res.data;
    })
  }

  public async managers() {
    return this.http().get(this.endpoint + "/managers").then(res => {
      return res.data
    })
  }

  public async examiners() {
    return this.http().get(this.endpoint + "/examiners").then(res => {
      return res.data
    })
  }

  public async coordinators() {
    return this.http().get(this.endpoint + "/coordinators").then(res => {
      return res.data
    })
  }

  public async impersonal(email) {
    return this.http().get(this.endpoint + "/user-token?email=" + email).then(res => {
      return res.data;
    })
  }

  async groups() {
    return this.http().get(this.endpoint + "/group").then(res => {
      return res.data
    })
  }

  async userLists(codes) {
    return await this.http().get(this.endpoint + "/user-list?team=" + codes).then(res => {
      return res.data
    })
  }

  async contacts() {
    return await this.http().get(this.endpoint + "/contacts").then(res => {
      return res.data
    })
  }

  async getTeams(id: string) {
    return await this.http().get(this.endpoint + "/" + id + "/teams").then(res => {
      return res.data
    })
  }

  async setTeam(userId, teamId) {
    return this.http().post(this.endpoint + "/" + userId + "/teams/" + teamId).then(res => {
      return res.data;
    })
  }

  async deleteTeam(userId, teamId) {
    return this.http().delete(this.endpoint + "/" + userId + "/teams/" + teamId).then(res => {
      return res.data
    })
  }
}


export default new UserService();